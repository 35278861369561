.page {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: $pcPadding;
  @include flex (flex-start, center, column);
  box-sizing: border-box;




}