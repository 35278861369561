.not-found-container{
  width: 100%;
  height: 100vh;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  img{
    width:100px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .not-found-container{
    padding: 0 25px;
  }
}

