.button{
  width: auto;
  height: 20px;
  background: #1a1a1a;
  padding: 16px 20px;
  @include flex(center, center, row);
  text-align: center;

  p{
    display: inline-block;
    font-size: 18px;
    color:white !important;
  }

  &:hover{
    cursor: pointer;
  }

  &:active{
    -webkit-box-shadow: none;
  }

}