@import "./styles/collections/collections";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

html{
  height: auto;
  width:100vw;
  max-width: 100vw;
  overflow-x: hidden !important;
}

body {
  font-family: 'Poppins', 'Source Sans Pro', serif !important;
  min-height: 100%;
  height:auto;
  width:100vw;
  margin:0;
  padding:0;
  overflow-x: hidden !important;
  overflow-y: hidden!important;
}

.App {
  height: 100vh;
  overflow-x: hidden !important;
  max-width: 100vw;
  width:100%;
  @include flex (flex-start, center, column);
  background-color: #2a2a2a;
}

a{
  text-decoration: none;
}

p, h2, a{
  color:white;
    max-width: 100vw;
}
