.header-container{
  width: 100%;
  height: 70px;
  margin-top: 50px;
  //background-color: #c70039;
  position: relative;
  @include flex(center, center, column);
  left:0;
  top:0;
}

.header-logo{
  width: auto;
  height: 100%;
  @include flex(center, center, row);

  img{
    width: 140px;
    margin-right: 20px;
    height: auto;
  }

  p{
    color:white;
  }
}

