@mixin size ($width, $height){
  width:$width;
  height:$height;
}

@mixin flex ($x, $y, $direction){
  display: flex;
  justify-content: $x;
  align-items: $y;
  flex-direction: $direction;
}
