.game-engine-wrapper{
  width:900px;
  height:500px;
  margin-top: 50px;
  position: relative;
  @include flex(center, center, column);

  .computer-only{
    display: flex;
    text-align: center;
    width: auto;
  }

  .close-full-screen{
    z-index: 1000000;
    width: auto;
    height: auto;
    top:25px;
    right:25px;
    position: fixed;
    display: none;
    svg{
      path{
        fill:white;
      }
      width:20px;
      height: auto;
    }

    &.active{
      display: flex;
    }

    &:hover{
      cursor: pointer;
    }
  }
}

.game-engine-button-wrapper{
  @include flex(flex-end, center, row);
  height: 100px;

  svg{
    font-size: 30px;
    path{
      fill:white;
    }
  }
}

.game-engine-button{
  display: flex;
  margin-top: 25px;

}

.game-engine-loader{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 0;
  display: none;
  background-color: transparent;

  &.active{
    @include flex(center, center, column);
  }

}

@media screen and (max-width: 1000px) {
  .game-engine-wrapper{
    width: 100%;
    height: auto;

    .close-full-screen{
      display: none;
      svg{
        display: none;
      }
    }
  }

  .game-engine-loader{
    display: none;
    &.active{
      display: none;
    }

    img{
      display: none;
    }
  }

  .game-engine-button{
    display: none;
  }
}
